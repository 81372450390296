import cx from 'classnames'
import { useEffect } from 'react'

import { type SanityBlock } from '@data/sanity/queries/types/blocks'

import Accordions from '@blocks/accordions'
import BlogPostCard from '@blocks/blog/blog-post-card'
import ContactForm from '@blocks/contact-form'
import ContentCarousel from '@blocks/content-carousel'
import CookieDeclaration from '@blocks/cookiebot/cookie-declaration'
import Freeform from '@blocks/freeform'
import Newsletter from '@blocks/newsletter'
import RestaurantMenu from '@blocks/restaurant-menu'
import VideoModule from './video'

interface GridBlockProps {
  block: SanityBlock
}

const GridBlock = ({ block }: GridBlockProps) => {
  useEffect(() => {
    // A quick fix for executing scripts from embedded HTML
    if (block._type === 'html' && block.code) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = block.code
      const scripts = tempDiv.querySelectorAll('script')
      scripts.forEach((script) => {
        const newScript = document.createElement('script')
        newScript.textContent = script.textContent
        document.body.appendChild(newScript)
      })
    }
  }, [block])

  switch (block._type) {
    case 'accordions': {
      const { items } = block

      return <Accordions items={items} />
    }

    case 'blogPostCard': {
      const { post, options } = block

      return <BlogPostCard post={post} options={options} />
    }

    case 'contactForm': {
      const {
        _key,
        service,
        heading,
        text,
        submitSuccessMessage,
        submitErrorMessage,
        customFormStrings,
      } = block

      return (
        <ContactForm
          id={_key}
          service={service}
          heading={heading}
          text={text}
          submitSuccessMessage={submitSuccessMessage}
          submitErrorMessage={submitErrorMessage}
          customFormStrings={customFormStrings}
        />
      )
    }

    case 'contentCarousel': {
      const { items } = block

      return <ContentCarousel items={items} />
    }

    case 'cookieDeclaration': {
      const { active, cookieBotId } = block

      if (!active || !cookieBotId) {
        return null
      }

      return <CookieDeclaration cookieBotId={cookieBotId} />
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = block

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'html': {
      const { code } = block

      if (!code) {
        return null
      }

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: code,
          }}
        />
      )
    }

    case 'iframe': {
      const { title, url, width, height } = block

      return (
        <iframe
          title={title}
          src={url}
          className={cx({ 'w-full': !width })}
          width={width}
          height={height}
        />
      )
    }

    case 'newsletter': {
      const {
        _key,
        title,
        description,
        variant,
        service,
        klaviyoListID,
        submit,
        successMsg,
        errorMsg,
        terms,
      } = block

      return (
        <Newsletter
          id={_key}
          title={title}
          description={description}
          variant={variant}
          service={service}
          klaviyoListID={klaviyoListID}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
        />
      )
    }

    case 'predefinedContentBlock': {
      const { contentBlocks } = block

      return (
        <>
          {contentBlocks?.map((contentBlock) => (
            <GridBlock key={contentBlock._key} block={contentBlock} />
          ))}
        </>
      )
    }

    case 'restaurantMenu': {
      const { heading, showCourses, categories, courses, strings } = block

      return (
        <RestaurantMenu
          heading={heading}
          showCourses={showCourses}
          courses={courses}
          categories={categories}
          strings={strings}
        />
      )
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio, borderRadius } = block

      return (
        <VideoModule
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )
    }
  }
}

export default GridBlock
