import cx from 'classnames'

interface HamburgerProps {
  isOpened?: boolean
}

const Hamburger = ({ isOpened }: HamburgerProps) => (
  <span
    className={cx(
      'relative flex flex-col items-center justify-center mx-auto w-7',
      'before:block before:relative before:w-full before:h-0 before:border-t before:border-current before:my-[6px] before:transition-transform before:duration-[.2s]',
      'after:block after:relative after:w-full after:h-0 after:border-t after:border-current after:my-[6px] after:transition-transform after:duration-[.2s]',
      {
        'mt-[-3px]': isOpened,
        'before:transform before:translate-y-[5px] before:opacity-0': isOpened,
        'after:transform after:translate-y-[-5px] after:opacity-0': isOpened,
      }
    )}
  >
    {/* Icon */}
    <span
      className={cx(
        'block relative w-full h-0 border-t border-current',
        'before:block before:absolute before:w-full before:h-0 before:border-t before:border-current before:top-[calc(50%-.5px)] before:transition-transform before:duration-[.2s]',
        'after:block after:absolute after:w-full after:h-0 after:border-t after:border-current after:top-[calc(50%-.5px)] after:transition-transform after:duration-[.2s]',
        {
          'border-transparent': isOpened,
          'before:opacity-0': !isOpened,
          'before:opacity-100 before:transform before:rotate-45': isOpened,
          'after:opacity-0': !isOpened,
          'after:opacity-100 after:transform after:-rotate-45': isOpened,
        }
      )}
    ></span>
  </span>
)

export default Hamburger
