import { type PortableTextMarkComponentProps } from '@portabletext/react'
import cx from 'classnames'

import { type SanityBlockColoredText } from '@data/sanity/queries/types/content'

type ColoredTextValue = SanityBlockColoredText

const colorClasses: Record<string, string> = {
  white: 'text-white',
  gray: 'text-gray',
  red: 'text-red',
  orange: 'text-orange',
  sand: 'text-sand',
}

const ColoredText = ({
  value,
  children,
}: PortableTextMarkComponentProps<ColoredTextValue>) => {
  return (
    <span
      className={cx(value?.color ? colorClasses[value.color] : '', {
        'opacity-50': !!value?.faded,
      })}
    >
      {children}
    </span>
  )
}

export default ColoredText
